// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bannerContainer{
    max-width: 567px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px !important;
    margin-bottom: 32px;
    box-sizing: border-box;
    min-height: 54px;
    background: #FFFFFF;
    border: 1px solid #DDDEE4;
    box-shadow: 2px 2px 10px rgba(40, 43, 62, 0.1);
    padding : 16px,8px,16px,8px;
}

.bannerText{
    font-family: 'Manulife JH Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 0px;    
    padding-left: 16px;
    padding-right: 16px;  
    color: #282B3E;
}

.iconBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:36px;
    height: 54px;
    padding-left: 3px;
    padding-right: 3px;
    background: #A00E18;
}

@media screen and (max-width: 320px) {
    .iconBox{
        height: 75px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/style/BannerMessageComponentStyle.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,0BAA0B;IAC1B,mBAAmB;IACnB,sBAAsB;IACtB,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,8CAA8C;IAC9C,2BAA2B;AAC/B;;AAEA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":["\n.bannerContainer{\n    max-width: 567px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-top: 8px !important;\n    margin-bottom: 32px;\n    box-sizing: border-box;\n    min-height: 54px;\n    background: #FFFFFF;\n    border: 1px solid #DDDEE4;\n    box-shadow: 2px 2px 10px rgba(40, 43, 62, 0.1);\n    padding : 16px,8px,16px,8px;\n}\n\n.bannerText{\n    font-family: 'Manulife JH Sans';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    margin-left: 0px;    \n    padding-left: 16px;\n    padding-right: 16px;  \n    color: #282B3E;\n}\n\n.iconBox{\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    width:36px;\n    height: 54px;\n    padding-left: 3px;\n    padding-right: 3px;\n    background: #A00E18;\n}\n\n@media screen and (max-width: 320px) {\n    .iconBox{\n        height: 75px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
