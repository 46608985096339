import React, { useContext, useEffect } from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Constants from '../../Utils/Constants';
import AndroidQR from './images/qr_caregiver_android.png';
import IOSQR from './images/qr_caregiver_ios.png';
import { MyContext } from '../../Context/MyContext';



const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        paddingTop: "25px"
    },
    subTitle: {
        marginBottom: "41px"
    },
    successHeaderStyles: {
        fontWeight: "500",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "48px",
        lineHeight: "58px",
        color: "#282B3E",
        margin: 0
    },
    successBodyStyles: {
        fontWeight: "600",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "22px",
        lineHeight: "28px",
        color: "#282B3E",
        fontStyle:"Demibold",
        align:"left",
        margin: 0
    },
    buttonTextStyles: {
        fontWeight: "500",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "18px",
        lineHeight: "22px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#FFFFFF"
    },
    inlineDisplay: {
        display: "flex",
        height:"100%",
        marginLeft:"8px"
    },
    inlineDisplay2:{
        display:Constants.CSS_DISPLAY,
        marginTop: "10px",
        marginBottom:"10px",
    },
    loadingText: {
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "12px",
        lineHeight: "20px",
        marginTop: "20px",
        marginBottom:"20px",
        display:Constants.CSS_DISPLAY,
        marginLeft:"8px"

    },
    labelStyle: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "16px",
        color: "#5E6073",
        lineHeight: "24px",
        fontStyle:"regular",
        align:"top"        
    },
    flexDisplay:{
        display:"flex"
    },
    qrBlockContainer:{
        paddingBottom: "25px",
        display:"flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    qrContainer:{
        display:"flex",
        maxWidth: "200px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "145px"
    },
    qrText:{
        fontFamily: Constants.FONT_FAMILY,
        display: "block",
        color: "#282B3E",
        fontSize: "19px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "24px",
        marginBottom: "20px"
    },
    qrCode:{
        display: "block"        
    }
}))

const CongratulationsPage = props => {

    const context = useContext(MyContext);    

    useEffect(()=> {       
        window.scrollTo(0, 0);
        context.setSessionTimeoutEnable(true);
    })
    const classes = useStyles();


    return (
        <Grid container className={classes.root}>
            <Grid item md={12} xs={12}>
                <p className={classes.successBodyStyles}>
                    Download John Hancock’s CareGiver app
                </p>
            </Grid>            
            <Grid item md={12} xs={12} className={classes.subTitle}>
                <label className={classes.labelStyle}>
                    Scan the QR code using your mobile device's camera.
                </label>
            </Grid>

            <Grid container md={12} xs={12} className={classes.qrBlockContainer}>
                <Grid item md={6} xs={12} className={classes.qrContainer}>
                    <span className={classes.qrText}>iOS</span>
                    <img className={classes.qrCode} src={IOSQR} alt='qrcode' width={200}/>
                </Grid>
                <Grid item md={6} xs={12} className={classes.qrContainer}>
                    <span className={classes.qrText}>Android</span>
                    <img className={classes.qrCode} src={AndroidQR} alt='qrcode' width={200}/>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CongratulationsPage;