
const MESSAGE_API = "/message";
const EXTRACT_TOKEN_API = "/extractToken";
const POST_CALL_API = "/postCall";
const VERIFY_USER_API = "/dlverify";



export default {
    MESSAGE_API,
    EXTRACT_TOKEN_API,
    POST_CALL_API,
    VERIFY_USER_API,
};