
export function isBlank(item){
    return item === ''
}

export function isNull(item){
    return item == null
}

export function camelize(str) {
    if(isNull(str)) return "";

    return str.split(" ").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(" ");
  }

  export function getQueryParams () {    
    const search = new URLSearchParams(window.location.search);
    return {
      jwt: search.get('jwt') || null,
      appId: search.get('appId') || null
    };
  };

const Utility = {
 isBlank, isNull, camelize, getQueryParams
}

export default Utility;