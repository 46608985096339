import React from 'react';
import URLConstants from '../Utils/URLConstants';
import axios from 'axios';
import * as Constants from '../constants/Constants';
import { getQueryParams } from '../Utils/utility';


const BACKEND_URL = process.env.REACT_APP_BACKEND ? process.env.REACT_APP_BACKEND : "http://localhost:55047";// "https://provider.registration-uat.johnhancock.com/backend"; 
const isLocalhost = window.location.href.includes('localhost');

console.log("BACKEND_URL==", process.env.REACT_APP_BACKEND);
console.log("APP_ENV==", process.env.REACT_APP_ENV);

const sendRequest = (endpoint, method, payload, sessionId) => {
    let params = getQueryParams();

    let _headers = {}
    
    if(payload){
        payload["appid"] = params.appId;
        _headers["Content-Type"] = "application/json";
    }

    const serviceUrl = BACKEND_URL+endpoint;
    const options = {
        url: serviceUrl,
        method: method || 'GET',
        headers: _headers,
        mode: isLocalhost ? 'cors' : 'same-origin',
        data: typeof payload === 'string' ? payload : JSON.stringify(payload)
    }
    return call(options);
}

async function call(options){

    return new Promise((resolve, reject)=> {
        axios(options)
            .then(response => {    
                let status = response.status;                
                if(status <300){
                    return response.data;
                } else{
                    return {"code": status, "message": "Something went wrong, api failed to return response!"};
                }    
            }).then(res=> {
                resolve(res);
            }).catch(e => {
                let msg = e.message === "Network Error" ? "Network Error" : "Something went wrong, cought unknown exception."
                reject({"code": 500, "message": msg});
            });
    });
}

const Service = {
    extractToken: (payload)=> sendRequest(URLConstants.EXTRACT_TOKEN_API, "POST", payload),
    checkMintenance: ()=> sendRequest(URLConstants.MESSAGE_API, "POST", {}),
    postCall: ()=> sendRequest(URLConstants.POST_CALL_API, "POST", {}),
    verifyUser: (payload)=> sendRequest(URLConstants.VERIFY_USER_API, "POST", payload)
}

export default Service;