
export function isEmpty (data) {
    let empty = false;
    if(!data || !data.trim()) empty = true
    return empty;
}


export function validateEmail(email) {
    // eslint-disable-next-line
    const re = /^[a-zA-Z0-9](([^<>()\[\]\\,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const repeatRegex = /([@#!#$%&'*+-/=?^_`{|])\1/
    return (re.test(String(email).toLowerCase()) && !repeatRegex.test(String(email).toLowerCase()));
}


export function getErrorMessage(key) {
    let validations =  {
        "documentId": "Please enter a valid driver's license number",
        "doe" : "Enter date of expiry",
        "doi" : "Enter date of issue",
        "dob": "Enter date of birth",
        "state": "Enter state",
        "dobyear": "Enter date of birth year"
    }
    return validations[key];
}
