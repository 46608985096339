import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { TextInput,  Dropdown, DROPDOWN_VARIANT, Button, Progress, INPUT_VARIANT } from '@manulife/mux';
import Service from '../../Services/Service';
import Constants from '../../Utils/Constants';
import InputError from './../../components/InputError';
import moment from "moment";
import { MyContext } from '../../Context/MyContext';
import State from '../../constants/State.json';
import { validate } from '../../components/PageBody/validate'
import LoaderComponent from './../../components/LoaderComponent';
import BannerMessageComponent from '../../components/BannerMessageComponent';



const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    gridSeparation: {
        paddingBottom: "32px"
    },
    paddingBottom50: {
        paddingBottom: "50px"
    },
    dropdownStyle: {
        paddingLeft: "0px"
    },
    headingStyle: {        
        fontWeight: "300",
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "22px",
        color: "#282B3E",
        lineHeight: "34px"
    },   
    labelStyle: {
        fontWeight: "400",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "14px",
        color: "#5E6073",
        lineHeight: "20px"
    },
    headerText: {
        margin: "0 auto",
        fontWeight: "500",
        fontFamily: Constants.FONT_FAMILY,
        fontSize: "48px",
        color: "#282B3E",
        lineHeight: "58px"
    },
    descText: {
        width: "820px",
        height: "272px",
        fontFamily: Constants.FONT_FAMILY,
        fontStyle: "normal",
        fontWeight: "300",
        fontSize: "22px",
        lineHeight: "34px",
        color: "#282B3E",
    },
    showIcon: {
        display: Constants.CSS_DISPLAY,
        marginLeft: "-25px",
        marginTop: "-20px",
    },
    flexDisplay: {
        display: "flex"
    },
    textFieldStyle:{
        fontWeight:"400",
        fontSize:"16px",
        lineHeight:"26px"        
    },
    rootStyle :{
        width:"820px"
    },
    errorStyle :{
        color: '#282B3E ',
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "48px",
        fontWeight:'600',
        margin:"0",
        lineHeight: "58px"
    },
    gridLowMargin: {
        margin: "0px",
        paddingBottom: "5px !important"
    },
    gridNoMargin: {
        margin: "0px",
        paddingBottom: "0px !important"
    },
    dateOfBirth: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    inputText: {
        color: "#00ff00"
    }
}))

const VerificationPage = props => {

    const context = useContext(MyContext);
    const { appId,isLoading, setLoading, onClick, setPage } = props;
    
    const [formValues, setFormValues] = useState({
        userName: '',
        firstName: '',
        lastName: '',
        documentId: '',
        dob: '',
        doi: '',
        doe: '',
        divUUID: '',
        jwt: '',
        isLoading: true,
        appid: appId,
        dobday: null,
        dobmonth: null,
        dobyear: null,
        doeday: null,
        doemonth: null,
        doeyear: null,
        doiday: null,
        doimonth: null,
        doiyear: null,
        state: null,
        validationPassed:false
    })


    const [errorState, setErrorState] = useState({
        firstName: { id: 'firstname', msg: '', isError: false },
        lastName: { id: 'lastname', msg: '', isError: false },
        //userName: { id: 'userName', msg: '', isError: false },
        documentId: { id: 'documentId', msg: '', isError: false },
        dob: { id: 'dob', msg: '', isError: false },
        doe: { id: 'doe', msg: '', isError: false },
        doi: { id: 'doi', msg: '', isError: false },
        dobday: { id: 'dobday', msg: '', isError: false },
        dobmonth: { id: 'dobmonth', msg: '', isError: false },
        dobyear: { id: 'dobyear', msg: '', isError: false },
        doiday: { id: 'doiday', msg: '', isError: false },
        doimonth: { id: 'doimonth', msg: '', isError: false },
        doiyear: { id: 'doiyear', msg: '', isError: false },
        doeday: { id: 'doeday', msg: '', isError: false },
        doemonth: { id: 'doemonth', msg: '', isError: false },
        doeyear: { id: 'doeyear', msg: '', isError: false },
        //divUUID: { id: 'divUUID', msg: '', isError: false },
        //jwt: { id: 'jwt', msg: '', isError: false },
        state: { id: 'state', msg: '', isError: false }
    })
    
    
    const classes = useStyles();
    const [verifyFailed, setVerifyFailed] = useState(false);
  

    useEffect(()=> {       
        window.scrollTo(0, 0);
        context.setSessionTimeoutEnable(true);
    })
    
    
    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key == "Enter") {
            submit();
        }
    };
   
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const buildDropDownMonth = () => {
        let monthObj = [];
        for (let i = 0; i < months.length; i++) {
            monthObj.push({ value: (i + 1).toString(), label: months[i].toString() });
        }
        return monthObj;
    }
    const buildDropDownYear = () => {
        let yearObj = [];
        let yearNow = parseInt(moment().format('YYYY'));
        let startYear = 1899;
        let length = yearNow - startYear;
        for (let i = 0; i < length; i++) {
            let year = yearNow - i;
            yearObj.push({ value: year.toString(), label: year.toString() });
        }
        return yearObj;
    }

    const buildDropDownExpiryYear = () => {
        let yearObj = [];
        let yearNow = parseInt(moment().format('YYYY'));
        let yearofExpiry = yearNow + 21;
        let length = yearofExpiry - yearNow;
        for (let i = 0; i < length; i++) {
            let year = yearNow + i;
            yearObj.push({ value: year.toString(), label: year.toString() })
        }
        return yearObj
    }

    const buildDropDownDay = (month, year) => {
        let dayObj = []
        let monthString = month == null ? moment().daysInMonth() : [parseInt(month) < 10 ? "0" + month : month]
        let yearString = month == null ? null : year;
        let days = moment(yearString + "-" + monthString, "YYYY-MM").daysInMonth()
        for (let i = 0; i < days; i++) {
            dayObj.push({ value: (i + 1).toString(), label: (i + 1).toString() })
        }
        return dayObj
    }  

    
    const changeHandler = (name, value) => {
        const newFormValues = { ...formValues, [name]: value };
        setFormValues(newFormValues);
        let validations = validate(newFormValues, name, errorState);
        setErrorState({...errorState, ...validations.errorState});
    }    

    function buildDropDownState() {
        
        let states = State.states;
        let statesObj = []
        for (let i = 0; i < states.length; i++) {
            statesObj.push({ value: states[i].abbreviation.toString(), label: states[i].name.toString() })
        }
        return statesObj;
    }


    function doNothing(){        
    }
   

    function formatValue(value){
        if(value.length===1){
            return "0"+value;
        }else{
            return value;
        }
    }

    function submit() {
        let validations = validate(formValues,'',errorState);
        setErrorState({...errorState, ...validations.errorState});
        if(validations.isPassed){
            setLoading(true);
            setVerifyFailed(false);

            const payload = {
                "jwt": props.jwt.trim(),
                "username": props.tokenInfo.username.trim(),
                "firstname": props.tokenInfo.firstname.trim(),
                "lastname": props.tokenInfo.lastname.trim(),                
                "appId":props.tokenInfo.appid.trim(),
                "divuuid":props.tokenInfo.divuuid.trim(),
                "documentid": formValues.documentId.trim(),
                "dob": formValues.dobyear+formatValue(formValues.dobmonth)+formatValue(formValues.dobday),
                "doi": formValues.doiyear+formatValue(formValues.doimonth)+formatValue(formValues.doiday),
                "doe": formValues.doeyear+formatValue(formValues.doemonth)+formatValue(formValues.doeday),
                "state": formValues.state.trim()
            };
            Service.verifyUser(payload)
                .then((response) => {
                    setLoading(false);
                    if (response.code === Constants.SUCCESS_CODE_0000) {
                        onClick(6);
                    } else if(response.code === "9075" || response.code === "400") {                        
                        setVerifyFailed(true);
                        return false;
                    } else if(response.code === "9076") { 
                        setPage(8); // when DL Verification failed 3 times.
                    } else{
                        onClick(5);
                    }
                }).catch(error => {
                    setLoading(false);
                    console.log("dlverification Error ========>", error);
                    if (error.message === "Network Error") {
                    }
                    onClick(5);     
                    
                });
        }
    }

    console.log("errorState==", errorState);
    
    return (
       
        <div className={classes.root} data-testid="registrationpage-root">
            {
                    isLoading && <LoaderComponent />
            }            
            <Grid container>
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Thank you, {props.tokenInfo.firstname} {props.tokenInfo.lastname}.</p>
                
                <Grid item xs={12} className={classes.gridSeparation}></Grid>
                <label className={classes.descText}>
                Your username <b>{props.tokenInfo.username} </b>has been created. Your security is our priority. <br/>
                Please complete an additional verification step below.
                </label>
                
                </div>   
                <Grid item md={12} xs={12} className={classes.gridSeparation}></Grid>       
                {
                    verifyFailed && <BannerMessageComponent />
                }
                <Grid item md={12} xs={12} className={classes.gridSeparation}>                    
                    <div className={classes.flexDisplay}>
                        <label className={classes.labelStyle}>Driver’s license number</label>
                    </div>
                    <TextInput variant={INPUT_VARIANT.BASIC_LINE}
                        id={formValues.documentId ? "documentIdselected" : "documentId"}
                        maxLength={60}
                        value={formValues.documentId}
                        required={true}
                        errors={[errorState.documentId]}
                        className={classes.inputText}
                        placeholder="Enter"
                        onChange={e => changeHandler("documentId", e)}
                        onKeyDown={()=> doNothing()}
                        callouts={true}
                        customStyle={{
                            rootStyle: {
                                width: "42%",
                            },
                            inputStyle: {
                                padding: '0px',

                            }
                        }} name="documentId"
                    />
                    {(errorState.documentId.isError) && <InputError inputId="documentId" formErrors={errorState} /> }
                </Grid>
                <Grid item md={12} xs={12} className={[classes.gridLowMargin, classes.gridSeparation]}>
                    <div>
                        <label className={classes.labelStyle}>Date of birth</label>
                    </div>  
                </Grid>
                <Grid item md={5} xs={12} className={[classes.gridSeparation, classes.dateOfBirth]}>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.dobmonth ? "dobmonthselected" : "dobmonth"}
                            data-testid="UserInfo-month"
                            className="dobMonth"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "5px",
                                }
                            }}
                            value={formValues.dobmonth}
                            ariaRequired={true}
                            errors={[errorState.dobmonth]}
                            onChange={e => changeHandler("dobmonth", e)}
                            callouts={true}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownMonth()}
                            placeholder="Month"
                            name='dobmonth' />
                            {(errorState.dobmonth.isError) && <InputError inputId="dobmonth" formErrors={errorState} /> }
                    </Grid>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.dobday ? "dobdayselected" : "dobday"}
                            data-testid="UserInfo-day"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "5px"
                                }
                            }}
                            value={formValues.dobday}
                            required={true}
                            errors={[errorState.dobday]}
                            callouts={true}
                            dropdownItems={buildDropDownDay(formValues.dobmonth, formValues.dobyear)}
                            onChange={e => changeHandler("dobday", e)}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            placeholder="Day"
                            name='dobday' />
                            {(errorState.dobday.isError) && <InputError inputId="dobday" formErrors={errorState} /> }
                    </Grid>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.dobyear ? "dobyearselected" : "dobyear"}
                            data-testid="UserInfo-year"
                            customStyle={{
                                rootStyle: {
                                    width: "100%"
                                },
                            }}
                            ariaRequired={true}
                            errors={[errorState.dobyear]}
                            callouts={true}
                            value={formValues.dobyear}
                            onChange={e => changeHandler("dobyear", e)}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownYear()}
                            placeholder="Year"
                            name='dobyear' />
                            {(errorState.dobyear.isError) && <InputError inputId="dobyear" formErrors={errorState} /> }
                    </Grid>
                </Grid>
                <Grid item md={12} xs={12} className={[classes.gridLowMargin, classes.gridSeparation]}>
                    <label className={classes.labelStyle}>
                        Issue Date
                    </label>
                </Grid>
                <Grid item md={5} xs={12} className={[classes.gridSeparation, classes.dateOfBirth]}>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.doimonth ? "doimonthselected" : "doimonth"}
                            data-testid="UserInfo-month"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "5px",

                                }
                            }}
                            value={formValues.doimonth}
                            ariaRequired={true}
                            errors={[errorState.doimonth]}
                            onChange={e => changeHandler("doimonth", e)}
                            callouts={true}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownMonth()}
                            placeholder="Month"
                            name='doimonth' />
                            {(errorState.doimonth.isError) && <InputError inputId="doimonth" formErrors={errorState} /> }
                    </Grid>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.doiday ? "doidayselected" : "doiday"}
                            data-testid="UserInfo-day"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "5px"
                                }
                            }}
                            value={formValues.doiday}
                            required={true}
                            errors={[errorState.doiday]}
                            dropdownItems={buildDropDownDay(formValues.doimonth, formValues.doiyear)}
                            onChange={e => changeHandler("doiday", e)}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            placeholder="Day"
                            name='doiday' />
                            {(errorState.doiday.isError) && <InputError inputId="doiday" formErrors={errorState} /> }
                    </Grid>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.doiyear ? "doiyearselected" : "doiyear"}
                            data-testid="UserInfo-year"
                            customStyle={{
                                rootStyle: {
                                    width: "100%"
                                },
                            }}
                            ariaRequired={true}
                            errors={[errorState.doiyear]}
                            value={formValues.doiyear}
                            onChange={e => changeHandler("doiyear", e)}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownYear()}
                            placeholder="Year"
                            name='doiyear' />
                            {(errorState.doiyear.isError) && <InputError inputId="doiyear" formErrors={errorState} /> }
                    </Grid>
                </Grid>

                <Grid item md={12} xs={12} className={[classes.gridLowMargin, classes.gridSeparation]}>
                    <label className={classes.labelStyle}>
                        Expiration Date
                    </label>
                </Grid>
                <Grid item md={5} xs={12} className={[classes.gridSeparation, classes.dateOfBirth]}>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.doemonth ? "doemonthselected" : "doemonth"}
                            data-testid="UserInfo-month"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "5px",

                                }
                            }}
                            value={formValues.doemonth}
                            ariaRequired={true}
                            errors={[errorState.doemonth]}
                            onChange={e => changeHandler("doemonth", e)}
                            callouts={true}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownMonth()}
                            placeholder="Month"
                            name='doemonth' />
                            {(errorState.doemonth.isError) && <InputError inputId="doemonth" formErrors={errorState} /> }
                    </Grid>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.doeday ? "doedayselected" : "doeday"}
                            data-testid="UserInfo-day"
                            customStyle={{
                                rootStyle: {
                                    width: "100%",
                                    paddingRight: "5px"
                                }
                            }}
                            value={formValues.doeday}
                            required={true}
                            errors={[errorState.doeday]}
                            dropdownItems={buildDropDownDay(formValues.doemonth, formValues.doeyear)}
                            onChange={e => changeHandler("doeday", e)}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            placeholder="Day"
                            name='doeday' />
                            {(errorState.doeday.isError) && <InputError inputId="doeday" formErrors={errorState} /> }
                    </Grid>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.doeyear ? "doeyearselected" : "doeyear"}
                            data-testid="UserInfo-year"
                            value={formValues.doeyear}
                            customStyle={{
                                rootStyle: {
                                    width: "100%"
                                },
                            }}
                            ariaRequired={true}
                            errors={[errorState.doeyear]}
                            onChange={e => changeHandler("doeyear", e)}
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownExpiryYear()}
                            placeholder="Year"
                            name='doeyear' />
                            {(errorState.doeyear.isError) && <InputError inputId="doeyear" formErrors={errorState} /> }
                    </Grid>
                </Grid>

                <Grid item md={12} xs={12} className={[classes.gridLowMargin, classes.gridSeparation]}>
                    <label className={classes.labelStyle}>State</label>
                </Grid>
                <Grid item md={5} xs={12} className={[classes.dateOfBirth, classes.paddingBottom50]}>
                    <Grid xs={12}>
                        <Dropdown
                            id={formValues.state ? "stateselected" : "state"}
                            data-testid="UserInfo-state"
                            value={formValues.state}
                            ariaRequired={true}
                            errors={[errorState.state]}
                            onChange={e => changeHandler("state", e)}                        
                            variant={DROPDOWN_VARIANT.BASIC_LINE}
                            dropdownItems={buildDropDownState()}
                            placeholder="Choose state"
                            customStyle={{
                                rootStyle: {
                                    width: "100%"
                                }
                            }}
                            name="state"/>                    
                        {(errorState.state.isError) && <InputError inputId="state" formErrors={errorState} /> }
                    </Grid>
                </Grid>                
                <Grid item xs={12} className={classes.paddingBottom50}>
                    <Button
                        name="verifyButton"
                        data-testid="registration-submitButton"
                        id="verifyButton"
                        onClick={e => submit()}//validateAndConfirm(formValues, errorState, setErrorState, props.setIsSubmitting)}
                        customStyle={{
                            buttonStyle: {
                                paddingLeft: '32px',
                                paddingRight: '32px',
                                paddingTop: '16px',
                                paddingBottom: '16px',
                                fontSize: "19px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "24px",
                            }
                        }}
                        disabled={isLoading}> Submit
                    </Button>                
                </Grid>
            </Grid>                  
        </div>
    );
};

export default VerificationPage;