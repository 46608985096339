
import moment from "moment";
import { getErrorMessage, isEmpty } from '../../Utils/Validations';

export function validateDaysInaMonth(day, month, year) {
    let monthString = month == null || year == null ? moment().daysInMonth() : [parseInt(month) < 10 ? "0" + month : month]
    let days = moment(year + "-" + monthString, "YYYY-MM").daysInMonth()
    if (day > days) {
        return false
    }
    return true
}

export function validate(formValues, name, errorState) {   
    let validationPassed = {isPassed: true};
    if(name==='documentId' || isEmpty(name)){ 
        let documentId = formValues.documentId?.trim();
        if (isEmpty(documentId) || documentId.length<3 || documentId.length>20) {
            errorState.documentId = { id: 'documentId', msg: '',errMsg: getErrorMessage("documentId"), isError: true };
            validationPassed.isPassed = false;
        } else {
            errorState.documentId = { id: 'documentId', errMsg: '', msg: '', isError: false };
        }
    }

    if(name==='state' || isEmpty(name)){ 
        if (isEmpty(formValues.state)) {
            errorState.state = { id: 'state', errMsg: getErrorMessage("state"), msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            errorState.state = { id: 'state', errMsg: '', msg: '', isError: false };
        }
    }


    //Date of Birth
    if(name==='dobday' || isEmpty(name)){ 
        if (!formValues.dobday) {
            errorState.dobday = { id: 'dobday', errMsg: 'Select day', msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            if (!validateDaysInaMonth(formValues.dobday, formValues.dobmonth, formValues.dobyear)) {
                errorState.dobday = { id: 'dobday',  errMsg: 'Select day', msg: '', isError: true };
                validationPassed.isPassed = false;
            } else {
                errorState.dobday = { id: 'dobday',  errMsg: '', msg: '', isError: false };
            }
        }
    }

    if(name==='dobmonth' || isEmpty(name)){ 
        if (!formValues.dobmonth) {
            errorState.dobmonth = { id: 'dobmonth', errMsg: 'Select month', msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            errorState.dobmonth = { id: 'dobmonth', errMsg: '', msg: '', isError: false };
        }
    }

    if(name==='dobyear' || isEmpty(name)){ 
        if (!formValues.dobyear) {
            errorState.dobyear = { id: 'dobyear', errMsg: 'Select year', msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            errorState.dobyear = { id: 'dobyear', errMsg: '', msg: '', isError: false };
        }
    }

    //Date of Issue
    if(name==='doiday' || isEmpty(name)){ 
        if (!formValues.doiday) {
            errorState.doiday = { id: 'doiday', errMsg: 'Select day', msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            if (!validateDaysInaMonth(formValues.doiday, formValues.doimonth, formValues.doiyear)) {
                errorState.doiday = { id: 'doiday', errMsg: 'Select day', msg: '', isError: true };
                validationPassed.isPassed = false;
            } else {
                errorState.doiday = { id: 'doiday', errMsg: '', msg: '', isError: false };
            }
        }
    }

    if(name==='doimonth' || isEmpty(name)){ 
        if (!formValues.doimonth) {
            errorState.doimonth = { id: 'doimonth', errMsg: 'Select month', msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            errorState.doimonth = { id: 'doimonth', errMsg: '', msg: '', isError: false };
        }
    }

    if(name==='doiyear' || isEmpty(name)){ 
        if (!formValues.doiyear) {
            errorState.doiyear = { id: 'doiyear', errMsg: 'Select year', msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            errorState.doiyear = { id: 'doiyear', errMsg: '', msg: '', isError: false };
        }
    }

    //Date of expiry
    if(name==='doeday' || isEmpty(name)){ 
        if (!formValues.doeday) {
            errorState.doeday = { id: 'doeday', errMsg: 'Select day', msg: '',  isError: true };
            validationPassed.isPassed = false;
        } else {
            if (!validateDaysInaMonth(formValues.doeday, formValues.doemonth, formValues.doeyear)) {
                errorState.doeday = { id: 'doeday', errMsg: 'Select day', msg: '', isError: true };
                validationPassed.isPassed = false;
            } else {
                errorState.doeday = { id: 'doeday', errMsg: '', msg: '', isError: false };
            }
        }
    }

    if(name==='doemonth' || isEmpty(name)){ 
        if (!formValues.doemonth) {
            errorState.doemonth = { id: 'doemonth', errMsg: 'Select month', msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            errorState.doemonth = { id: 'doemonth', errMsg: '', msg: '', isError: false };
        }
    }
    if(name==='doeyear' || isEmpty(name)){ 
        if (!formValues.doeyear) {
            errorState.doeyear = { id: 'doeyear',errMsg: 'Select year', msg: '', isError: true };
            validationPassed.isPassed = false;
        } else {
            errorState.doeyear = { id: 'doeyear',errMsg: '', msg: '', isError: false };
        }
    }
    validationPassed["errorState"] = errorState;

    return validationPassed;

}

function checkSpecialChar(text){         
    let pattern = /^[A-Za-z0-9_@.-]*$/;   
    let result = text.match(pattern);
    //console.log(result);
    if(result !=null){
        return true;
    }else{
        return false;
    }
}