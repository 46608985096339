// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.errorText{
    font-family: 'Manulife JH Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-left: 0px;
    color: #C14A36;
    display:flex;
}
.errorContainer{
    margin-top: 0 !important;
}

.messageSpace{
    padding-left: 6px;
    padding-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/style/InputError.css"],"names":[],"mappings":";AACA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":["\n.errorText{\n    font-family: 'Manulife JH Sans';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 20px;\n    margin-left: 0px;\n    color: #C14A36;\n    display:flex;\n}\n.errorContainer{\n    margin-top: 0 !important;\n}\n\n.messageSpace{\n    padding-left: 6px;\n    padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
