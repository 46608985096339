import React, { useState, useEffect, useContext } from 'react';
import { VerificationPage,CongratulationsPage,ErrorPage } from '../../Pages';
import { getQueryParams } from '../../Utils';
import { MyContext } from '../../Context/MyContext';
import { makeStyles } from '@material-ui/core/styles';
import Service from '../../Services/Service';
import Constants from '../../constants/Constants';
import { useIdleTimer } from 'react-idle-timer';
import StartOverComponent from './../../components/StartOverComponent';
import DLVerificationErrorPage from '../../Pages/DLVerificationErrorPage/DLVerificationErrorPage';


const useStyles = makeStyles((theme) => ({
    rootStyle :{
        width:"820px"
    },
    errorStyle :{
        color: '#282B3E ',
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "48px",
        fontWeight:'600',
        margin:"0",
        lineHeight: "58px"
    },
    labelStyle: {        
        fontWeight: "300",
        fontFamily: "\"Manulife JH Sans\"",
        fontSize: "22px",
        color: "#282B3E",
        lineHeight: "34px"
    }    
}))


const PageBody = props => {

    const context = useContext(MyContext);
    const [page, setPage] = useState(props.page);
    const classes = useStyles();

    let { appid, jwt } = getQueryParams();
        
    const handleOnIdle = e => {
        if(context.sessionTimeoutEnable){
            setPage(7);
        }
    }

    // 15 min session timeout if in Idle mode
    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    })

    const [isLoading, setLoading] = useState(true);

    const [errorType, setErrorType] = useState('');

    const [systemErrorCount, setSystemErrorCount] = useState(0);
    const [sessionTimeoutEnable, setSessionTimeoutEnable] = useState(false)

    const [tokenInfo, setTokenInfo] = useState({});

    useEffect(() => {
        setLoading(true);
        if (jwt && appid) {
            extractToken(appid);
        }
        else {
           setPage(5);
           setLoading(false);
        }
    }, [appid, jwt]);


    function extractToken(appid) {
        if (systemErrorCount > 0) setSystemErrorCount(0);
        let payload = {
            "appId": appid,
            "jwt": jwt
        }

        Service.extractToken(payload)
            .then((response) => {                
                setLoading(false);                
                if (Constants.EXTRACT_TOKEN_SUCCESS_0000 === response.code.toString()) {
                    setTokenInfo(response.details);                    
                    setPage(0);                   
                    if (systemErrorCount > 0) setSystemErrorCount(0);
                } else if (response.code === "9070" || response.code === "9006" || response.code === "2004") {
                    setSystemErrorCount(systemErrorCount + 1);
                    setPage(3);
                } else if (response.code === "200" && response.message === "Maintenance") {
                    setSystemErrorCount(systemErrorCount + 1);
                    setPage(1);
                } else if (response.code === "200" && response.message === "Technical") {
                    setSystemErrorCount(systemErrorCount + 1);
                    setPage(2);
                } else {
                    // Unhandled error codes
                    setPage(5);
                    setSystemErrorCount(systemErrorCount + 1);
                }
            }).catch(error => {
                setLoading(false);
                console.log("DL Extract token Error ========>", error);
                if (error.message === "Network Error") {
                    setErrorType("Technical")
                }
                setPage(5);
            })
    }

    const onClick = pageNum => {
        setSystemErrorCount(0);
        setPage(pageNum)
    }

    switch (page) {
        case 0:
            return (
                <VerificationPage
                    onClick={onClick}
                    appId={appid}
                    jwt={jwt}
                    setSessionTimeoutEnable={setSessionTimeoutEnable}
                    isLoading = {isLoading}
                    setLoading = {setLoading}
                    tokenInfo={tokenInfo}
                    setSystemErrorCount={setSystemErrorCount}
                    setPage={setPage}>
                </VerificationPage>
            );
        case 1:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>We are currently down for scheduled maintenance.</p>
                <label className={classes.labelStyle}>
                We are working as quickly as possible to restore our services and apologize for any inconvenience.
                </label>
                </div>    
            );
        case 2:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>We are currently experiencing technical issues.</p>
                <label className={classes.labelStyle}>
                We are working as quickly as possible to restore our services and apologize for any inconvenience.
                </label>
                </div>    
            );
        case 3:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Hmm...</p>
                <label className={classes.labelStyle}>
                    It looks like this invite is no longer valid.
                </label>
                </div>

            );
        case 4:
            return (
                <div className={classes.rootStyle}>
                <ErrorPage/>
                </div>

            );
        case 5:
            return (
                <div className={classes.rootStyle}>
                    <p className={classes.errorStyle}>Hmm...</p>
                    <label className={classes.labelStyle}>
                        It looks like we're having some trouble generating this page.
                    </label>
                </div>

            );
        case 6:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Registration complete!</p>
                <label className={classes.labelStyle}>
                <br/>Download the CareGiver mobile app, where you can manage and submit care sessions for {tokenInfo.insuredfirstname} {tokenInfo.insuredlastname}’s long-term care policy.
                </label>                    
                <CongratulationsPage/>
                </div>

            );
        case 7:
            return (
                <div className={classes.rootStyle}>
                <p className={classes.errorStyle}>Your session has expired due to inactivity.</p><br/>
                <label className={classes.labelStyle}>For your security, we haven’t saved any of your personal data. Please try again by clicking below button.           
                </label>
                <StartOverComponent/>
                </div>

            );
        case 8:
            return <DLVerificationErrorPage />
        default:
            return (
                <div className={classes.rootStyle}>
                    <p className={classes.errorStyle}>Hmm...</p>
                    <label className={classes.labelStyle}>
                        It looks like we're having some trouble generating this page.
                    </label>
                </div>

            );
    }

};

export default PageBody;