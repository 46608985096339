import React, { useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { MyContext } from '../../Context/MyContext';

const font = "\"Manulife JH Sans\"";
const useStyles = makeStyles(() => ({
    root: {
        width: "100%"
    },
    contentTitle: {
        fontWeight: "600",
        fontFamily: font,
        fontSize: "48px",
        lineHeight: "58px",
        fontStyle: "normal",
        color: "#282B3E",
        marginBottom: "30px"
    },
    content: {
        fontWeight: "300",
        fontFamily: font,
        fontSize: "22px",
        lineHeight: "34px",
        fontStyle: "normal",
        color: "#282B3E"
    },
    gridSeparation: {
        paddingBottom: "25px"
    }
}));

const DLVerificationErrorPage = () => {

    const context = useContext(MyContext);

    
    useEffect(() => {
        window.scrollTo(0, 0);
        context.setSessionTimeoutEnable(false);
    }, []);

    

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container md={8} xs={12} className={classes.gridSeparation}>
                <span className={classes.contentTitle}>Unable to verify your license information</span>
                <span className={classes.content}>
                    Your license information is unable to be verified.<br/>
                    <br/>
                    Please email a photo of your license to caregiver@jhancock.com so that we can verify your information. Please allow 5 business days for processing. If you have any questions please call 844-798-3001.
                </span>
            </Grid>
                   
        </div>
    );
};

export default DLVerificationErrorPage
