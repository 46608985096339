import React from 'react'
import "../style/BannerMessageComponentStyle.css"
import { Grid } from '@mui/material';
import { ReactComponent as InfoWarning } from './Images/icon_colour_navy.svg';



const BannerMessageComponent = (props) => {
    return (
          <Grid item md={6} xs={12}>                           
                <div className="bannerContainer">
                  <span className="iconBox"><InfoWarning/></span>
                  <span className="bannerText">The driver’s license cannot be validated. Please check your details and try again.</span>
                </div>
          </Grid>
    );
}

export default BannerMessageComponent;
